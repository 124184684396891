<template>
  <DataTableComponent :table="table"></DataTableComponent>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";
export default {
  data() {
    return {
      table: {
        modal: "ApplicantLogs",
        path: "",
        headers: [
          {
            text: this.$i18n.t("User"),
            value: "user",
            filterable: false,
            sortable: false,
            width: "120px",
          },
          { text: this.$i18n.t("Action"), value: "action_" },
          { text: this.$i18n.t("Changes"), value: "changes", sortable: false },
          { text: this.$i18n.t("Date"), value: "date", filterable: false },
          {
            text: this.$i18n.t("Time"),
            value: "time",
            filterable: false,
            sortable: false,
          },
        ],
        title: this.$i18n.t("Activity"),
        apiEndPoints: {
          list: "",
          create: "",
          edit: "",
          delete: "",
        },
        editedItem: {
          grade_name: "",
          short_name: "",
        },
        applicant_id: "",
        permissions: {
          list: "show-applicants-logs",
        },
      },
    };
  },
  methods: {},
  components: {
    DataTableComponent,
  },
  mounted() {
    this.applicant_id = this.$router.currentRoute.params.applicant_id;
    this.table.apiEndPoints.list =
      "applicant/getApplicantLogs/" + this.applicant_id;
    // this.apiEndPoints.list = "applicant/getApplicantLogs/" + this.applicant_id;
    this.table.path = "applicants/log/" + this.applicant_id;
  },
};
</script>

<style></style>
